@import '../node_modules/bootstrap/scss/functions';

// named colors
$min-contrast-ratio: 1.2;
$success: tint-color(teal, 20%);
$primary: rgb(0,157,220);
$danger: tint-color(red, 40%);
$warning: shade-color(yellow, 10%);
$info: tint-color(indigo, 30%);

// other
$btn-close-color:red;

// spacers
$spacer: 1rem !default;
$spacers: (
  0: 0,
  1: $spacer * .25,
  15: $spacer * .375,
  2: $spacer * .5,
  25: $spacer * .75,
  3: $spacer,
  4: $spacer * 1.5,
  425: $spacer * 1.875,
  45: $spacer * 2.25,
  5: $spacer * 3,
  6: $spacer * 6,
) !default;

@import "../node_modules/bootstrap/scss/bootstrap";
