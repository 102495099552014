/* mobile */

html,body {
  overflow:hidden;
  overscroll-behavior-y:none;
  user-select:none;
  height: 100%;
  margin: 0;
}
#root {
  height:100%;
}
@media (pointer: coarse) {
  html {
    touch-action:none;
  }
}
@media (max-width: 576px) { /* sm breakpoint */
  .header-logo.searching {
    margin-right:0;
  }
  .header-logo.searching > img {
    max-width:0;
    padding:0;
    opacity:0;
  }
  .header-logo.searching > .demo-badge {
    max-width:0;
    max-height:0;
    opacity:0;
  }
}
.app-content {
  touch-action:pan-y;
  overflow-y:scroll;
}

/* desktop only */

.demo-badge {
  transition:all 0.5s;
  z-index:1000;
  margin-left:2.8vw;
  margin-top:-14px;
  opacity:1;
  overflow:hidden;
  max-width:50px;
  max-height:24px;
}
.name-box {
  width:300px;
}
@media (min-width: 576px) { /* sm breakpoint */
  .fs-sm-4 {
    font-size: calc(1.275rem + .3vw) !important;
  }
  .fs-sm-3 {
    font-size: calc(1.3rem + .6vw) !important;
  }
  .w-sm-75 {
    width: 75% !important;
  }
  .demo-badge {
    margin-left: 16px;
  }
  .name-box {
    width:400px;
  }
}
@media (min-width: 768px) { /* md breakpoint */
  .demo-badge {
    margin-left: 24px;
  }
}

/* bootstrap missing */

.bg-success-emphasis {
  background-color: var(--bs-success-text-emphasis) !important;
}

/* header */

button.nav-link {
  border-color:transparent !important;
}
button.nav-link:hover {
  border-color:var(--bs-light-color) !important;
}
a.nav-link:not(.active) {
  border-color:transparent !important;
}
a.nav-link:hover:not(.active) {
  border-color:var(--bs-light-color) !important;
}

/* buttons */

.signup-btn {
  transition:transform 0.2s;
}
.signup-btn:hover {
  transform:scale(1.07);
}
.btn-icon-lg:before {
  transform:scale(1.25);
}
.btn-icon {
  opacity:0.75;
  cursor:pointer;
}
.navbar-toggler:focus {
  box-shadow:none !important;
}
.btn-icon:hover {
  opacity:1;
}
.header-logo {
  transition:all 0.5s;
  margin-right:0.75rem;
}
.header-logo > img {
  transition:all 0.5s;
  max-width:50px;
  padding:2px;
  opacity:1;
}

/* 3d look */

.panel {
  box-shadow: 4px 4px 0 0 #eee;
  border-radius: var(--bs-border-radius-lg) !important;
  background-color: rgba(var(--bs-light-rgb),1) !important
}
.panel-inset {
  box-shadow: inset 2px 2px 0 0 #eee;
  border-radius: var(--bs-border-radius-lg) !important;
  background-color: rgba(var(--bs-white-rgb),1) !important
}

/* other */

.spinner-container {
  transition:opacity 1s;
}
table tr:last-child th,
table tr:last-child td {
    border: none;
}